.ms-login {
  background: url("https://uploadv2.viphrm.com/filemanager/preview/?path=288572/202307/ddf290bef0504287a6034d73cb5eacd2.jpg")
    no-repeat top center / cover;
  width: 100vw;
  height: 100vh;
  position: relative;

  .login-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    column-gap: 160px;

    .login-topic {
      color: #ffffff;
      user-select: none;
      & > div:first-child {
        font-size: 46px;
      }
      & > div:last-child {
        margin-top: 20px;
        font-size: 18px;
      }
    }

    .login-box {
      box-sizing: border-box;
      width: 400px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 40px 40px 40px;

      .logo {
        width: 160px;
      }

      .form {
        width: 100%;
        margin-top: 10px;

        .ant-form-item {
          .ant-form-item-label {
            line-height: 30px;
          }

          input {
            border: 0;
            border-bottom: 1px solid #e4e7ec;
            border-radius: 0;
            padding-left: 0;

            &:focus {
              box-shadow: none;
            }
          }
        }

        .error {
          color: #f5222d;
        }

        .login-btn {
          margin-top: 20px;
          height: 40px;
        }
      }
    }
  }

  .copy-right {
    font-size: 12px;
    opacity: 0.8;
    color: #fff;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    user-select: none;
  }
}
