$primary-color: #1890ff;
$success-color: #52c41a;
$warning-color: #faad14;
$error-color: #f5222d;
$font-color: #373d45;
$hover-color: #40a9ff;
$hover-bg-color-ghost: #f8faff;
$hover-bg-color-primary: #1b66e6;

// 各模块主背景色
$module-bg-color: #f0f2f5;
// 各容器背景
$container-bg-color: #fff;
// 主容器padding
$container-padding: 16px;
