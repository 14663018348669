$globalBackgroundColor: #f7f8fa;
$moduleBackgroundColor: #fff;
.smartHR-content {
  height: 100%;
  background-color: $globalBackgroundColor !important;
  min-width: 1366px;
  #childView {
    height: calc(100vh - 88px);
    margin: 16px;
    & > div {
      height: 100% !important;
    }
    .msPayList {
      min-height: 100% !important;
      background-color: #f0f2f5 !important;
    }
    .econtractLayout {
      margin-top: 0 !important;
    }
  }
}
.externalAddr {
  height: 100vh;
}

.smartht_bread {
  height: 42px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
  align-items: center;
  border-radius: 6px;
  position: sticky;
  top: 0px;
  z-index: 5;
  button span {
    margin-left: 2px;
  }
}

.external {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
  min-height: initial;
}
