@import "antd/lib/layout/style";
@import "antd/lib/menu/style";
@import "antd/lib/table/style";
@import "antd/lib/pagination/style";
@import "antd/lib/dropdown/style";
@import "antd/lib/avatar/style";
@import "antd/lib/icon/style";
@import "antd/lib/button/style";
@import "antd/lib/breadcrumb/style";
// @import "antd/lib/row/style";
@import "antd/lib/grid/style";
@import "antd/lib/modal/style";
@import "./common/common.scss";

// $primary-color: #006CB7;
// $success-color: #64AF13;
// $warn-color: #EF8200;
// $error-color: #EA4B6B;

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: $font-color;

  /* scroll bar */
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-track {
    background-color: #fff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 6px;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

  // 去除btn-link的padding
  button.ant-btn-link {
    padding: 0;
  }
}

.primary-color {
  color: $primary-color;
}

.success-color {
  color: $success-color;
}

.warning-color {
  color: $warning-color;
}

.error-color {
  color: $error-color;
}

// 定义操作列
.action-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 设置表头列
.setting-column {
  padding-right: 24px !important;
}

.es220 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 196px;
}

.es200 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 176px;
}

.es150 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 126px;
}

.es180 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 156px;
}

.es100 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 76px;
}

.tip-remark {
  white-space: nowrap;
}