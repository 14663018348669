.ms-home {
  margin: 0;
  height: 100%;
  padding-top: 7%;
  text-align: center;

  p:nth-child(1) {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  p:nth-child(2) {
    font-size: 14px;
    color: #999;
  }
}
